body{
    background-image: url(https://cdn.pixabay.com/photo/2014/11/06/18/52/accessories-519693_1280.jpg);
    background-size: cover;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    background-position: center;
    background-attachment: fixed;
}
.home1-main-body{
    background-color:var(--base-color);
    height:100vh;
    width: 100vw;
    opacity: 0.8;
    overflow: hidden;
}
.main-content{
align-items: center;
}
.main-content>img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 10rem;  
}
.main-content>:nth-child(2){
    font-family: "Kaushan Script", cursive;
    display: block;
    margin-top: 1rem;
    font-size: 50px;
    line-height: 1.3;
    font-weight: 500;
    color: white;
    rotate: -6deg;
    text-align: center;
}
.main-content>:nth-child(3){
    font-family: "Space Mono", Arial, serif;
    display: block;
    margin-top: 2rem;
    font-size: 16px ;
    color: white;
    text-align: center;
}
.icons{
    margin-top: 2rem;
    font-family: 'icomoon';
    font-size:xx-large;
    text-align: center;
    
}
.icons>a{
    margin-left: 1rem;
    margin-right: 1rem;
    color: white;
    text-decoration: none;
}
/* @media  screen and (max-width:700px) {
    .main-content>:nth-child(2){
        font-size: 25px;
    }
} */