.home3-main{
    background-color: var(--base-color);
    padding-bottom: 5rem;
}
.hireme-heading>h2{
    text-align: center;
    color: white;
    padding-top: 5rem;
    font-size: 40px;
}
.hireme>p{
    padding: 10px 10px;
    text-align: center;
    font-family: "Space Mono", Arial, serif;
    color: #e7dfdf;
    font-size: 18px;
    margin-top: 2rem;
    font-style: italic;
}
.btn{
    background: white;
    color:var(--base-color);
    margin-top: 2rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: none;
    width: fit-content;
    border-radius: 30px;
    height: 58px;
    font-weight: 400;
    padding: 18px 36px;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-family: "Space Mono", Arial, serif;

}
.btn:hover{
    background-color: white;
    color: var(--base-color);
}
