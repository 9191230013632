.contact{
    background-color: var(--base-color);
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
.first-half-contact{
    background-color: white;
    overflow:hidden;
}
.contact-image{
    text-align: center;
    margin-top: 7rem;
    margin-bottom: 5rem;
}
.contact-image>img{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
}
.contact-icons{
    margin-top: 4rem;
    padding-bottom: 1rem;
    height: 20vh;
    overflow: hidden;
}

.about-button>a{
    text-decoration: none;
  }
  .about-button>a>button{
    
    margin-top: -20px;
    background-color:var(--base-color);
    color: white;
  }
  .about-button>a>button:hover{
    
    
    background-color:white;
    
  }

  
  @media screen and (max-width:900px) {

    .contact-icons{
        margin-top: 2rem;
        padding-bottom: 1rem;
        height: 20vh;
        overflow: hidden;
    }
    
  }

